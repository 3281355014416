import React from 'react';
import { selectCurrentProduction, selectToggleProductionVolume, toggleProductionVolume } from '../../store/mainReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { PRODUCTION_UNITS_KEYS, PRODUCTION_UNITS_LABELS } from '../../utils/enums';
import { Production } from '../../models/Production';
import Nuclear from '../../assets/images/productions-vert/nuclear.svg';
import HYDRAULICS from '../../assets/images/productions-vert/hydrau.svg';
import PHOTOVOLTAIC from '../../assets/images/productions-vert/photovoltaic.svg';
import WIND_ONSHORE from '../../assets/images/productions-vert/marin.svg';
import WIND_OFFSHORE from '../../assets/images/productions-vert/terrestre.svg';
import THERMAL from '../../assets/images/productions-vert/thermal.svg';
import BIOENERGIE from '../../assets/images/productions-vert/bioenergy.svg';
import Eye from "../../assets/images/eye.svg";
import EyeClose from "../../assets/images/eye-close.svg";
import styled from 'styled-components';


const CustomDiv = styled.div`
    .energy-green {
      padding-left: 25px;
      margin-top: 8px;
    }
      .production-title, .production-value {
      font-size: 21px;
      font-weight: 600;
      }
      /*
    .production-label {
      padding-left: 11px;
    }
      */
    .production-icon {
      width: 24px;
      height: 24px;
    }
`
const getIconByCode = (code: string) => {
  switch(code) { 
    case PRODUCTION_UNITS_KEYS.NUCLEAR: {
      return Nuclear;
    } 
    case PRODUCTION_UNITS_KEYS.HYDRAULICS: {
      return HYDRAULICS;
    }
    case PRODUCTION_UNITS_KEYS.THERMAL: {
      return THERMAL;
    }
    case PRODUCTION_UNITS_KEYS.WIND_OFFSHORE: {
      return WIND_OFFSHORE;
    }
    case PRODUCTION_UNITS_KEYS.WIND_ONSHORE: {
      return WIND_ONSHORE;
    }
    case PRODUCTION_UNITS_KEYS.PHOTOVOLTAIC: {
      return PHOTOVOLTAIC;
    }
    case PRODUCTION_UNITS_KEYS.BIOENERGIE: {
      return BIOENERGIE;
    }
 } 
}

const Productions = () => {
  const currentProduction = useAppSelector(selectCurrentProduction);
  const active = useAppSelector(selectToggleProductionVolume);
  const dispatch = useAppDispatch();
  currentProduction.norenewable.filter(
    ({value}) => {
      return value > 0
    }
  );
  const handleClick = () => {
    dispatch(toggleProductionVolume());
  }
  return (
    <CustomDiv className='row'>
      <div  onClick={handleClick} className="col-12">
        <div className='row align-items-end ps-3 pt-3'>
          <div className="col-5 production-title">Production</div>
          <div className="col-5 text-end  production-value">{`${currentProduction.total[0].value} MW`}</div>
          <div className="col-2 text-end" style={{paddingRight: "30px"}}>
            <img style={{position: "relative", bottom: "8px", cursor: "pointer"}} src={active ? Eye : EyeClose} alt="Eye" className='icon-prod' />
          </div>
        </div>
      </div>
      <div className="col-12">
        {currentProduction.norenewable.filter(
          ({value}) => {
            return value > 0
          }
        ).map(({code, value}: Production, index: number) => {
          return (
            <div className='row align-items-end ps-3' key={index}>
              {/*<div className="col-1 text-start">
                <img className='production-icon' src={getIconByCode(code)} alt="" />
              </div>*/}
              <div className="col-7 production-label">{(PRODUCTION_UNITS_LABELS as any)[code]}</div>
              <div  style={{paddingRight: '55px'}} className="col-5 text-end">{`${value} MW`}</div>
            </div>
          )
        })} 
      </div>
      <div className="col-12">
        <div className="row bg-info">
          <div className="col-12 ps-3">
          {currentProduction.renewable.filter(
          ({value}) => {
            return value > 0
          }
        ).map(({code, value}: Production, index: number) => {
              return (
                <div className={index === 0 ? 'row align-items-end pt-2' : 'row align-items-end'} key={index}>
                  {/*<div className="col-1">
                    <img className='production-icon' src={getIconByCode(code)} alt="" />
                  </div>*/}
                  <div className="col-7 production-label">{(PRODUCTION_UNITS_LABELS as any)[code]}</div>
                  <div  style={{paddingRight: '55px'}} className="col-5 text-end">{`${value} MW`}</div>
                </div>
              )
            })}
          </div>
        </div>
        
      </div>
    </CustomDiv>
  );
}

export default Productions;