import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Icon2 from "../../assets/images/icon2.svg";
import Legend from '../control-panel/legend';
const ModalLegendComponent = () => {
  const [showRight, setShowRight] = useState(false);

  const handleCloseRight = () => setShowRight(false);
  const handleShowRight = () => setShowRight(true);

  return (
    <div className="p-2">
      <div className='row'>
        <div className="col-6 z-3" onClick={handleShowRight} style={{cursor: 'pointer'}}>
            <img src={Icon2} alt=''/>
        </div>
      </div>

      {/* Right Sidebar Modal */}
      <Modal show={showRight} onHide={handleCloseRight}>
        <Modal.Header className='p-3 pb-1' closeButton>
        <Modal.Title>Légende</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Legend />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalLegendComponent;
